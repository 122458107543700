import { type KubeIdentifier } from '../kubernetes/client/types'
import { isNamespacedKubeIdentifier } from '../kubernetes/client/utils'

import { namespaceNameToProjectName } from './misc'
import { type Reference } from './reference'

/**
 * An identifier for a cluster-wide resource in Kubernetes.
 */
export type GlobalScopeIdentifier = {
  /**
   * The name of the resource.
   *
   * The name is unique within the cluster, if this is a cluster-wide resource.
   * The name is unique within the project, if this is a project-scoped resource.
   *
   * A name always has the format `^[a-z0-9]([-a-z0-9]*[a-z0-9])?$`.
   */
  readonly name: string
}

/**
 * An identifier for a project/namespace-scoped resource in Kubernetes.
 */
export type ProjectScopeIdentifier = GlobalScopeIdentifier & {
  /**
   * The name of the project/namespace the resource belongs to.
   *
   * A project name always has the format `^[a-z0-9]([-a-z0-9]*[a-z0-9])?$`.
   */
  readonly projectName: string
}

/**
 * An identifier for a resource in Kubernetes.
 *
 * Can be either a {@link GlobalScopeIdentifier} or {@link ProjectScopeIdentifier}
 *
 * Use {@link isGlobalScopeIdentifier} and {@link isProjectScopeIdentifier} to check which one it is.
 */
export type Identifier = GlobalScopeIdentifier | ProjectScopeIdentifier

/**
 * Checks if an identifier is a {@link GlobalScopeIdentifier}.
 *
 * @param value the identifier
 * @returns true if the identifier is a {@link GlobalScopeIdentifier}, false otherwise
 */
export const isGlobalScopeIdentifier = (value: unknown): value is GlobalScopeIdentifier =>
  typeof value === 'object' && value !== null && !('projectName' in value)

/**
 * Checks if an identifier is a {@link ProjectScopeIdentifier}.
 *
 * @param value the identifier
 * @returns true if the identifier is a {@link ProjectScopeIdentifier}, false otherwise
 */
export const isProjectScopeIdentifier = (value: unknown): value is ProjectScopeIdentifier =>
  typeof value === 'object' && value !== null && 'projectName' in value

/**
 * Checks if an identifier is an {@link Identifier}.
 *
 * @param value the identifier
 * @returns true if the identifier is a {@link Identifier}, false otherwise
 * @see isGlobalScopeIdentifier
 * @see isProjectScopeIdentifier
 */
export const isIdentifier = (value: unknown): value is Identifier =>
  isGlobalScopeIdentifier(value) || isProjectScopeIdentifier(value)

/**
 * Joins any identifier to a single string.
 *
 * A global/cluster-wide identifier will just return its name.
 * A project/namespace-scoped identifier will return its project name and name joined by a slash.
 *
 * Example:
 * ```ts
 * joinIdentifier({ name: 'foo' }) // 'foo'
 * joinIdentifier({ projectName: 'bar', name: 'foo' }) // 'bar/foo'
 * ```
 *
 * @param value the identifier
 * @returns the joined identifier
 */
export const joinIdentifier = (value: Identifier): string =>
  isProjectScopeIdentifier(value) ? `${value.projectName}/${value.name}` : value.name

/**
 * Splits a string into an identifier.
 *
 * If the string contains a slash, it will be split into a {@link ProjectScopeIdentifier}.
 * Otherwise, it will be split into a {@link GlobalScopeIdentifier}.
 *
 * Example:
 * ```ts
 * splitIdentifier('foo') // { name: 'foo' }
 * splitIdentifier('bar/foo') // { projectName: 'bar', name: 'foo' }
 * ```
 *
 * @param value the string to split
 * @returns the identifier
 */
export const splitIdentifier = (value: string): Identifier =>
  value.indexOf('/') === -1
    ? { name: value }
    : { projectName: value.split('/')[0], name: value.split('/')[1] }

/**
 * Expands a reference to a full identifier.
 *
 * If the reference already contains a project name, it will be returned as-is.
 * Otherwise, the project name will be added to the reference.
 *
 * Example:
 * ```ts
 * refToProjectScopeIdentifier({ name: 'foo' }, 'bar') // { projectName: 'bar', name: 'foo' }
 * refToProjectScopeIdentifier({ projectName: 'bar', name: 'foo' }, 'bar') // { projectName: 'bar', name: 'foo' }
 * ```
 *
 * @param ref the reference
 * @param projectName the project name to add
 * @returns the full identifier
 */
export const refToProjectScopeIdentifier = (
  ref: Reference,
  projectName: string,
): ProjectScopeIdentifier =>
  ref.projectName ? (ref as ProjectScopeIdentifier) : { projectName, name: ref.name }

/**
 * Converts a resource to a reference.
 *
 * Example:
 * ```ts
 * resourceToReference({ projectName: 'bar', name: 'foo', extraProp: 'test' }) // { projectName: 'bar', name: 'foo' }
 * ```
 */
export const toReference = <T extends Identifier>(identifier: T): Reference =>
  isProjectScopeIdentifier(identifier)
    ? { projectName: identifier.projectName, name: identifier.name }
    : { name: identifier.name }

/**
 * Converts an identifier to a reference.
 *  * Example:
 * ```ts
 * resourceToReference({ namespace: 'project-bar', name: 'foo'}) // { projectName: 'bar', name: 'foo' }
 * ```
 */
export const identifierToReference = (identifier: KubeIdentifier | Identifier): Reference => {
  return isProjectScopeIdentifier(identifier)
    ? { name: identifier.name, projectName: identifier.projectName }
    : isNamespacedKubeIdentifier(identifier)
    ? { name: identifier.name, projectName: namespaceNameToProjectName(identifier.namespace) }
    : { name: identifier.name }
}
