import { default as LoadingSkeleton } from 'react-loading-skeleton'
import { twMerge } from 'tailwind-merge'

type SkeletonProps = {
  readonly className?: string
  readonly containerClassName?: string
}

export const Skeleton = ({ className, containerClassName }: SkeletonProps) => (
  <LoadingSkeleton
    containerClassName={containerClassName}
    className={twMerge(
      'dark:bg-gray-600 dark:after:bg-gradient-to-r dark:after:from-gray-600 dark:after:via-gray-500 dark:after:to-gray-600',
      className,
    )}
  />
)
