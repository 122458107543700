/**
 * The different annotations that are used in eva-specific k8s resources.
 */
export const ANNOTATIONS = Object.freeze({
  projectName: 'funkecloudservice.de/project-name',
  displayName: 'funkecloudservice.de/display-name',
  description: 'funkecloudservice.de/description',
  updatedAt: 'funkecloudservice.de/updated-at',
  imageRef: 'funkecloudservice.de/image-ref',
  diskRef: 'funkecloudservice.de/disk-ref',
  diskSnapshotRef: 'funkecloudservice.de/disk-snapshot-ref',
  templateRef: 'funkecloudservice.de/template-ref',
  parameterSecretRef: 'funkecloudservice.de/parameter-secret-ref',
})

/**
 * Converts a k8s size string to GiB.
 *
 * A k8s size string comes with a unit, e.g. "1Kib", "1Mi", "1Gi", "1G", "1M", "1K".
 * In Eva, all sizes are numeric in Gib.
 *
 * Examples:
 * ```ts
 * k8sSizeStringToGib('1Gi') // 1
 * k8sSizeStringToGib('1G') // 1
 * ```
 *
 * @param sizeString the k8s size string
 * @returns the size in GiB
 */
export const k8sSizeStringToGib = (sizeString?: string): number => {
  if (!sizeString) {
    return 0
  }
  const sizeMatch = sizeString.match(/^(\d+)([KMG]i?)$/)
  if (!sizeMatch) {
    throw new Error(`Invalid size string: ${sizeString}`)
  }
  const size = parseInt(sizeMatch[1])
  const unit = sizeMatch[2]
  switch (unit) {
    case 'K':
    case 'Ki':
      return size / Math.pow(1024, 2)
    case 'M':
    case 'Mi':
      return size / 1024
    case 'G':
    case 'Gi':
      return size
    default:
      throw new Error(`Unknown size unit: ${unit}`)
  }
}
