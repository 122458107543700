import { ANNOTATIONS } from '../misc'

import {
  type FunkeKubeManifestFields,
  type KubeClusterScopedIdentifier,
  type KubeIdentifier,
  type KubeManifest,
  type KubeNamespaceScopedIdentifier,
  type KubeNamespaceScopedManifest,
  type KubeProjectScopedManifest,
} from './types'

/**
 * Checks if a value is a valid {@link KubeManifest}.
 * @param value - The value to check.
 * @returns `true` if the value is a valid Kubernetes manifest (i.e. it has the typical `apiVersion`, `kind`, `metadata` structure), `false` otherwise.
 */
export const isKubeManifest = (
  value: unknown,
): value is KubeManifest & {
  readonly apiVersion: string
  readonly kind: string
  metadata: { name: string }
} =>
  typeof value === 'object' &&
  value !== null &&
  'apiVersion' in value &&
  'kind' in value &&
  'metadata' in value &&
  typeof value.metadata === 'object' &&
  value.metadata !== null &&
  'name' in value.metadata

/**
 * Checks if a manifest is a Funke manifest, i.e., it has group `eva.funkecloudservice.de` and belongs to Funke CRD.
 * @param manifest the manifest to check
 * @returns `true` if the manifest is a Funke manifest, `false` otherwise.
 */
export const isFunkeKubeManifest = (
  manifest: KubeManifest,
): manifest is KubeManifest & FunkeKubeManifestFields =>
  manifest.apiVersion?.startsWith('eva.funkecloudservice.de') ?? false

/**
 * Checks if a manifest has a creation timestamp (located under `metadata.creationTimestamp`).
 * @param value - The manifest to check.
 * @returns `true` if the manifest has a creation timestamp, `false` otherwise.
 */
export const isManifestWithCreationTimestamp = (
  value: unknown,
): value is KubeManifest & { readonly metadata: { creationTimestamp: string } } =>
  isKubeManifest(value) && 'creationTimestamp' in (value.metadata ?? {})

/**
 * Checks if a manifest has an updated timestamp.
 * @param value - The manifest to check.
 * @returns `true` if the manifest has an updated timestamp, `false` otherwise.
 */
export const isManifestWithUpdatedTimestamp = (
  value: unknown,
): value is KubeManifest & {
  readonly metadata: { annotations: { [ANNOTATIONS.updatedAt]: string } }
} => isKubeManifest(value) && ANNOTATIONS.updatedAt in (value.metadata?.annotations ?? {})

/**
 * Gets the namespace name from a namespaced manifest.
 *
 * **WARNING:** Will return 'default' if the namespace is not defined, i.e. also for cluster-scoped manifests.
 * @param manifest - The namespaced manifest.
 * @returns The namespace name of the manifest. Returns 'default' if the namespace is not defined.
 */
export const getNamespaceNameFromManifest = (manifest: KubeNamespaceScopedManifest): string =>
  manifest.metadata?.namespace ?? 'default'

/**
 * Gets the name from a manifest.
 * @param manifest - The manifest.
 * @returns The name of the manifest. Returns an empty string if the name is not defined.
 */
export const getNameFromManifest = (manifest: KubeManifest): string => manifest.metadata?.name ?? ''

/**
 * Gets the display name from a manifest.
 * @param manifest - The manifest.
 * @returns The display name of the manifest. Returns an empty string if the display name is not defined.
 */
export const getDisplayNameFromManifest = (manifest: KubeManifest): string =>
  (isFunkeKubeManifest(manifest)
    ? manifest.spec?.displayName
    : manifest.metadata?.annotations?.[ANNOTATIONS.displayName]) ?? ''

/**
 * Gets the description from a manifest.
 * @param manifest - The manifest.
 * @returns The description of the manifest. Returns an empty string if the description is not defined.
 */
export const getDescriptionFromManifest = (manifest: KubeManifest): string =>
  (isFunkeKubeManifest(manifest)
    ? manifest.spec?.description
    : manifest.metadata?.annotations?.[ANNOTATIONS.description]) ?? ''

/**
 * Gets the updated timestamp from a manifest.
 * @param manifest - The manifest.
 * @returns The updated timestamp of the manifest. Returns an empty string if the updated timestamp is not defined.
 */
export const getUpdatedAtFromManifest = (manifest: KubeManifest): string =>
  (isFunkeKubeManifest(manifest)
    ? manifest.spec?.updatedAt
    : manifest.metadata?.annotations?.[ANNOTATIONS.updatedAt]) ?? ''

/**
 * Gets the project name from a project-scoped manifest.
 * @param manifest - The project-scoped manifest.
 * @returns The project name of the manifest. Returns the namespace name without the 'project-' prefix.
 */
export const getProjectNameFromManifest = (manifest: KubeProjectScopedManifest): string =>
  getNamespaceNameFromManifest(manifest).replace(/^project-/, '')

/**
 * Gets the creation timestamp from a manifest.
 * @param manifest - The manifest.
 * @returns The creation timestamp of the manifest. Returns an empty string if the creation timestamp is not defined.
 */
export const getCreatedTimestampFromManifest = (manifest: KubeManifest): string =>
  isManifestWithCreationTimestamp(manifest) ? manifest.metadata.creationTimestamp : ''

/**
 * Gets the updated timestamp from a manifest.
 * @param manifest - The manifest.
 * @returns The updated timestamp of the manifest. Returns an empty string if the updated timestamp is not defined.
 */
export const getUpdatedTimestampFromManifest = (manifest: KubeManifest): string =>
  (isFunkeKubeManifest(manifest)
    ? manifest.spec?.updatedAt
    : manifest.metadata?.annotations?.[ANNOTATIONS.updatedAt]) ?? ''

/**
 * Adds a creation timestamp to a manifest if it doesn't already have one.
 * @param manifest - The manifest.
 * @param createdAt - The creation timestamp to add. If not provided, the current timestamp will be used.
 * @returns The manifest with the creation timestamp added.
 */
export const addCreatedTimestampToManifest = <T extends KubeManifest>(
  manifest: T,
  createdAt?: string,
): T =>
  isManifestWithCreationTimestamp(manifest)
    ? manifest
    : {
        ...manifest,
        metadata: {
          ...manifest.metadata,
          creationTimestamp: createdAt ?? new Date().toISOString(),
        },
      }

/**
 * Adds an updated timestamp to a manifest if it doesn't already have one.
 * @param manifest - The manifest.
 * @param updatedAt - The updated timestamp to add. If not provided, the current timestamp will be used.
 * @returns The manifest with the updated timestamp added.
 */
export const addUpdatedTimestampToManifest = <T extends KubeManifest>(
  manifest: T,
  updatedAt?: string,
): T =>
  getUpdatedAtFromManifest(manifest) !== ''
    ? manifest
    : isFunkeKubeManifest(manifest)
    ? {
        ...manifest,
        spec: {
          ...manifest.spec,
          updatedAt: updatedAt ?? new Date().toISOString(),
        },
      }
    : {
        ...manifest,
        metadata: {
          ...manifest.metadata,
          annotations: {
            ...manifest.metadata?.annotations,
            [ANNOTATIONS.updatedAt]: updatedAt ?? new Date().toISOString(),
          },
        },
      }

/**
 * Replaces the creation timestamp in a manifest.
 * @param manifest - The manifest.
 * @param createdAt - The new creation timestamp. If not provided, the current timestamp will be used.
 * @returns The manifest with the creation timestamp replaced.
 */
export const replaceCreatedTimestampInManifest = <T extends KubeManifest>(
  manifest: T,
  createdAt?: string,
): T & { metadata: { creationTimestamp: string } } => ({
  ...manifest,
  metadata: {
    ...manifest.metadata,
    creationTimestamp: createdAt ?? new Date().toISOString(),
  },
})

/**
 * Replaces the updated timestamp in a manifest.
 * @param manifest - The manifest.
 * @param updatedAt - The new updated timestamp. If not provided, the current timestamp will be used.
 * @returns The manifest with the updated timestamp replaced.
 */
export const replaceUpdatedTimestampInManifest = <T extends KubeManifest>(
  manifest: T,
  updatedAt?: string,
): T =>
  isFunkeKubeManifest(manifest)
    ? {
        ...manifest,
        spec: {
          ...manifest.spec,
          updatedAt: updatedAt ?? new Date().toISOString(),
        },
      }
    : {
        ...manifest,
        metadata: {
          ...manifest.metadata,
          annotations: {
            ...manifest.metadata?.annotations,
            [ANNOTATIONS.updatedAt]: updatedAt ?? new Date().toISOString(),
          },
        },
      }

/**
 * Adds both creation and updated timestamps to a manifest if they don't already exist.
 * @param manifest - The manifest.
 * @returns The manifest with the timestamps added.
 */
export const addTimestampsToManifest = <T extends KubeManifest>(manifest: T): T =>
  addUpdatedTimestampToManifest(addCreatedTimestampToManifest(manifest))

/**
 * Checks if a value is a cluster-scoped Kubernetes identifier.
 * @param value - The value to check.
 * @returns `true` if the value is a cluster-scoped Kubernetes identifier, `false` otherwise.
 */
export const isClusterScopedKubeIdentifier = (
  value: unknown,
): value is KubeClusterScopedIdentifier =>
  typeof value === 'object' &&
  value !== null &&
  'name' in value &&
  !('namespace' in value) &&
  !('projectName' in value)

/**
 * Checks if a value is a namespaced Kubernetes identifier.
 * @param value - The value to check.
 * @returns `true` if the value is a namespaced Kubernetes identifier, `false` otherwise.
 */
export const isNamespacedKubeIdentifier = (
  value: unknown,
): value is KubeNamespaceScopedIdentifier =>
  typeof value === 'object' &&
  value !== null &&
  'name' in value &&
  'namespace' in value &&
  !('projectName' in value)

/**
 * Converts a {@link KubeNamespaceScopedManifest} to a {@link KubeNamespaceScopedIdentifier}.
 * @param manifest - The namespaced manifest.
 * @returns The namespaced Kubernetes identifier.
 */
export const toKubeNamespaceScopedIdentifier = <T extends KubeNamespaceScopedManifest>(
  manifest: T,
): KubeNamespaceScopedIdentifier => ({
  name: getNameFromManifest(manifest),
  namespace: getNamespaceNameFromManifest(manifest),
})

/**
 * Joins a {@link KubeIdentifier} into a string representation.
 * @param identifier - The Kubernetes identifier.
 * @returns The string representation of the identifier.
 */
export const joinKubeIdentifier = (identifier: KubeIdentifier): string =>
  isNamespacedKubeIdentifier(identifier)
    ? `${identifier.namespace}/${identifier.name}`
    : identifier.name

/**
 * Joins a {@link KubeManifest} into an identifying string representation.
 * @param manifest - The Kubernetes manifest.
 * @returns The string representation of the manifest.
 */
export const joinKubeManifestIdentifier = (
  manifest: KubeManifest,
  isGlobalScoped: boolean,
): string =>
  isGlobalScoped
    ? getNameFromManifest(manifest)
    : `${getNamespaceNameFromManifest(manifest)}/${getNameFromManifest(manifest)}`
