import { type ProjectScopeIdentifier } from '../domain/identifier'
import { type Reference } from '../domain/reference'

/**
 * The namespace for default eva resources (like default os images).
 */
export const DEFAULTS_NAMESPACE = 'fcs-defaults'

export const isDefaultResource = (resource: ProjectScopeIdentifier | Reference) =>
  resource.projectName === DEFAULTS_NAMESPACE
