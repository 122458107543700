import slugify from 'slugify'

import { DEFAULTS_NAMESPACE } from '../helpers/defaultResources'
import { type KubeClusterScopedManifest } from '../kubernetes/client/types'
import {
  getNameFromManifest,
  getNamespaceNameFromManifest,
  isKubeManifest,
} from '../kubernetes/client/utils'

import { isProjectScopeIdentifier } from './identifier'
import { type ProjectScopeResource } from './resource'

/**
 * Converts a project name to a namespace name.
 *
 * Example:
 * ```ts
 * projectNameToNamespaceName('foo') // 'project-foo'
 * ```
 *
 * @param projectName the project name
 * @returns the namespace name
 */
export const projectNameToNamespaceName = (projectName: string) =>
  projectName === DEFAULTS_NAMESPACE ? DEFAULTS_NAMESPACE : `project-${projectName}`

/**
 * Converts a namespace name to a project name.
 *
 * Example:
 * ```ts
 * namespaceNameToProjectName('project-foo') // 'foo'
 * ```
 *
 * @param namespaceName the namespace name
 * @returns the project name
 */
export const namespaceNameToProjectName = (namespaceName: string) =>
  namespaceName === DEFAULTS_NAMESPACE ? DEFAULTS_NAMESPACE : namespaceName.replace(/^project-/, '')

/**
 * Checks if a namespace name is a project scoped namespace.
 *
 * Example:
 * ```ts
 * isProjectScopedNamespace('project-foo') // true
 * isProjectScopedNamespace('foo') // false
 * ```
 *
 * @param namespaceName the namespace name
 * @returns true if the namespace name is a project scoped namespace, false otherwise
 */
export const isProjectScopedNamespace = (namespaceName?: string) =>
  namespaceName?.startsWith('project-')

const findUniqueNameWithSuffix = (i: number, name: string, names: string[]): string =>
  names.includes(`${name}-${i}`) ? findUniqueNameWithSuffix(i + 1, name, names) : `${name}-${i}`

/**
 * Creates a new unique name based on the display name and makes sure it's unique within the existing names by appending a number if necessary.
 *
 * Example:
 * ```ts
 * getNewUniqueName('Foo Bar', []) // 'foo-bar'
 * getNewUniqueName('Foo Bar', ['foo-bar']) // 'foo-bar-2'
 * getNewUniqueName('Ä Ö Ü', []) // 'ae-oe-ue'
 * ```
 *
 * @param displayName the display name
 * @param existingNames the existing names
 * @returns the new unique name
 */
export const getNewUniqueName = (displayName: string, existingNames: readonly string[]) => {
  const slugifiedName = slugify(displayName, { locale: 'de', lower: true, strict: true })
  const matchingNames = existingNames.filter(name =>
    name.match(new RegExp(`^${slugifiedName}(-[0-9]*)?$`)),
  )

  if (matchingNames.length === 0 || !matchingNames.includes(slugifiedName)) return slugifiedName

  return findUniqueNameWithSuffix(2, slugifiedName, matchingNames)
}

/**
 * Creates a new resource name based on the display name and makes sure it's unique globally or within the specified project or namespace by appending a number if necessary.
 *
 * Example:
 * ```ts
 * getNewResourceName('Foo Bar', [], 'foo') // 'foo-bar'
 * getNewResourceName('Foo Bar', [{ projectName: 'foo', name: 'bar' }], 'foo') // 'foo-bar-2'
 * getNewResourceName('Ä Ö Ü', [], 'foo') // 'ae-oe-ue'
 * ```
 *
 * @param displayName the display name
 * @param resources the existing resources
 * @param projectNameOrNamespace the project name or namespace to scope the uniqueness check
 * @returns the new resource name
 * */
export const getNewResourceName = (
  displayName: string,
  resources: readonly ProjectScopeResource[] | readonly KubeClusterScopedManifest[],
  projectNameOrNamespace?: string,
) => {
  const existingNames = resources.flatMap(resource =>
    isKubeManifest(resource)
      ? !projectNameOrNamespace || getNamespaceNameFromManifest(resource) === projectNameOrNamespace
        ? [getNameFromManifest(resource)]
        : []
      : isProjectScopeIdentifier(resource) &&
        (!projectNameOrNamespace || resource.projectName === projectNameOrNamespace)
      ? [resource.name]
      : [],
  )

  return getNewUniqueName(displayName, existingNames)
}
